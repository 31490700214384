const globalConfig = require('../../config');
const { isLiveJs, isProxy } = require('../helpers/mode');

/**
 * Is used to check if the feature is enabled or not.
 *
 * @returns boolean
 */
exports.isEnabled = () => {
  return false;
};

function configureTranslationEnvironment(
  checkAuthenticationToken,
  startTraversingNow,
  checkIfTraversingCompleted,
  config
) {
  if (isLiveJs() || isProxy()) {
    if (isProxy()) {
      globalConfig.web_owner = false;
    }

    // used selected lang code for because in upper portion of the code we already picked code from query param and assigned it to selected_lang_code
    globalConfig.default_lang_code = globalConfig.web_owner
      ? globalConfig.selected_lang_code
      : globalConfig.default_lang_code;

    if (globalConfig.selected_lang_code === globalConfig.source_lang_code) {
      globalConfig.selected_lang_code = globalConfig.default_lang_code;
    }

    checkAuthenticationToken(globalConfig.expiry_token_time, config.apiKey);

    if (globalConfig.subscription_status_active) {
      startTraversingNow();
      checkIfTraversingCompleted();
      // set text direction rtl for urdu
      const rtlLangs = ['ur', 'ar'];
      if (rtlLangs.includes(globalConfig.selected_lang_code)) {
        document.body.setAttribute('dir', 'rtl');
      } else {
        document.body.setAttribute('dir', 'ltr');
      }
    }
  }
}

module.exports = {
  configureTranslationEnvironment
};

const { getClientConfig } = require('../helpers/utils');

const clientConfig = getClientConfig('dummy');

/**
 * Perform initialization tasks for this feature
 *
 * @param {object} config Configuration passed by initialization code
 * @param {object} urlObj Document URL object
 */
exports.initialize = async (config, urlObj) => {
  if (urlObj.hostname.includes(clientConfig.hostname)) {
    clientConfig.enabled = true;
  }
};

/**
 * Perform initialization tasks for this feature on server side
 *
 * @param {object} config Configuration passed by initialization code
 * @param {object} urlObj Document URL object
 */
exports.initializeServer = async (config, urlObj) => {
  if (urlObj.hostname.includes(clientConfig.hostname)) {
    clientConfig.enabled = true;
  }
};

/**
 * Is used to check if the client code is enabled or not.
 *
 * @returns boolean
 */
exports.isEnabled = () => {
  return false;
};

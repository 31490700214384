const global_variables = require('../../config');

// open database and return db instance
async function openDatabase() {
  try {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('translationsDb', 1);

      request.onupgradeneeded = function (event) {
        const db = event.target.result;

        //  first check if we have language code array, create store for each user subscribed language
        if (
          global_variables.lang &&
          Array.isArray(global_variables.lang) &&
          global_variables.lang.length
        ) {
          global_variables.lang.forEach((langCode) => {
            // check if source language, to ensure no objecStore is created for source language code
            if (langCode.code !== global_variables.source_lang_code) {
              if (!db.objectStoreNames.contains(langCode.code)) {
                db.createObjectStore(langCode.code, { autoIncrement: true });
              }
            }
          });
        }
        // check if subdomainObjectStore(language code) exist and check if store is already created if not, create it.
        else if (
          global_variables.subdomainObjectStore &&
          !db.objectStoreNames.contains(global_variables.subdomainObjectStore)
        ) {
          db.createObjectStore(global_variables.subdomainObjectStore, {
            autoIncrement: true
          });
        }
      };

      request.onsuccess = function (event) {
        // Return database instance when it's successfully opened
        resolve(event.target.result);
      };

      request.onerror = function (event) {
        reject('Error opening database: ' + event.target.error);
      };
    });
  } catch (error) {
    console.log('Error opening database');
  }
}

// open or create IndexDb and store translations data
async function storeTranslationsData(tranlationsObj) {
  try {
    const database = await openDatabase();

    const storeTranslations = await new Promise((resolve, reject) => {
      if (
        global_variables.subdomainObjectStore &&
        database.objectStoreNames.contains(global_variables.subdomainObjectStore)
      ) {
        const transaction = database.transaction(
          [global_variables.subdomainObjectStore],
          'readwrite'
        );

        const objectStore = transaction.objectStore(global_variables.subdomainObjectStore);

        if (tranlationsObj && typeof tranlationsObj === 'object') {
          tranlationsObj.createdTime = new Date();
          tranlationsObj.selectedLanguageCode = global_variables.selected_lang_code;

          const storedData = objectStore.put(
            tranlationsObj,
            cleanUrl(global_variables.document_url_full)
          );
        }

        transaction.oncomplete = function () {
          resolve();
        };

        transaction.onerror = function (event) {
          reject('Transaction error: ' + event.target.error);
        };
      }

      resolve();
    });
  } catch (error) {
    console.log('IndexDb error');
  }
}

// retrieve data from indexDb of selected store(language code)
async function retrieveTranslationsDataFromIndexDb() {
  try {
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
      if (
        global_variables.subdomainObjectStore &&
        db.objectStoreNames.contains(global_variables.subdomainObjectStore)
      ) {
        const transaction = db.transaction([global_variables.subdomainObjectStore], 'readonly');
        const objectStore = transaction.objectStore(global_variables.subdomainObjectStore);

        const url = cleanUrl(global_variables.document_url_full);
        // open cursor to iterate through all records
        const cursorRequest = objectStore.openCursor();
        const results = [];

        cursorRequest.onsuccess = function (event) {
          const cursor = event.target.result;

          if (cursor) {
            // get the key of the current record
            const key = cursor.key;

            if (key.includes(url)) {
              return resolve(cursor.value);
            }

            // continue iterating to next record
            cursor.continue();
          } else {
            // finished iterating over all records
            resolve(null);
          }
        };

        cursorRequest.onerror = function (event) {
          console.error('Error fetching records:', event.target.error);
          resolve(null);
        };
      } else {
        resolve(null);
      }
    });
  } catch (error) {
    console.log('No data found in indexdDb');
    return null;
  }
}

// remove # fragment and queryParams from url
function cleanUrl(url) {
  const parsedUrl = new URL(url);

  parsedUrl.hash = '';
  parsedUrl.search = '';

  return parsedUrl.toString();
}

module.exports = {
  storeTranslationsData,
  retrieveTranslationsDataFromIndexDb
};

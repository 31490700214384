const global_variables = require('../../config');

function node_blacklist(node, config = global_variables) {
  // node blacklist by class condition

  if (
    config.blacklist_classes &&
    config.blacklist_classes
      .map((item) => node.getAttribute('class') && isExactMatch(node.getAttribute('class'), item))
      .some(Boolean)
  ) {
    // console.log(
    //     "ignoring element with classname " + node.getAttribute("class")
    // );

    return true;
  }

  // node blacklist by id condition
  if (
    node &&
    node.getAttribute &&
    node.getAttribute('id') &&
    config.blacklist_ids.includes(node.getAttribute('id'))
  ) {
    console.log('ignoring element with id ' + node.getAttribute('id'));
    return true;
  }

  return false;
}

function isExactMatch(str, match) {
  return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
}

function escapeRegExpMatch(s) {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

module.exports = {
  node_blacklist
};

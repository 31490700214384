/**
 * Is used to check if the feature is enabled or not.
 *
 * @returns boolean
 */
exports.isEnabled = () => {
  return false;
};

function initialization(config, globalConfig) {
  if (config.ui_mode) {
    globalConfig.should_render_ui = config.ui_mode;
  } else {
    globalConfig.should_render_ui = globalConfig.web_owner ? 'native' : 'custom';
  }

  globalConfig.ui_mode = globalConfig.should_render_ui;
}

module.exports = {
  initialization
};

const { showErrorMessage } = require('../helpers/utils');

// this function will atfirst translate webpage into english language so that english text of every node will be saved in english_att attribute
function contentEdit(node) {
  switch (node.nodeName) {
    case '#text': {
      if (node.textContent.trim().length != 0) {
        node.english_attr = node.textContent;
      }
      break;
    }
  }
}

// this function will call the save -translation patch api for sending newly added edited text to server
async function contextEditorPatchApi(loader) {
  try {
    if (!global_variables._access_token) {
      return;
    }

    if (
      global_variables.url_object.searchParams.has('dota-token') &&
      global_variables.url_object.searchParams.has('language_code')
    ) {
      global_variables.document_url =
        global_variables.url_object.origin + global_variables.url_object.pathname;
      if (!global_variables.document_url.endsWith('/')) {
        global_variables.document_url += '/';
      }
    }
    const res = await fetch(global_variables.context_editor_api, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${global_variables.dota_token}`
      },
      body: JSON.stringify({
        url: global_variables.document_url,
        language_code: global_variables.selected_lang_code,
        translations: global_variables.content_editable
      })
    });
    if (res.status == 401) {
      showErrorMessage();
      if (loader) {
        loader.remove();
      }
      global_variables.unAuthApiResponse = true;
    }
    const data = await res.json();
    return data;
  } catch (error) {
    throw new Error('save-translation response-> you are unauthorized');
  }
}

// in context editor when web owner edit anything
function DataAttributeDataChangeListioner() {
  const element = document.querySelector('.devnagri_unsavedChanges');
  if (element) {
    element.remove();
  }
  const divForUnSavedChange = document.createElement('div');
  divForUnSavedChange.innerText = 'You have unsaved changes';
  divForUnSavedChange.classList.add('devnagri_unsavedChanges');
  divForUnSavedChange.id = 'devnagri_optionElementt';
  const outerMostDiv = document.getElementById('devnagri_outermostdiv');
  outerMostDiv.appendChild(divForUnSavedChange);
}

// this will remove event listener
function RemoveDataAttributeDataChangeListioner() {
  window.removeEventListener('DOMCharacterDataModified', DataAttributeDataChangeListioner);
}

// this will add event listener when web owner edit any text
function AddDataAttributeDataChangeListioner() {
  if (global_variables.web_owner) {
    RemoveDataAttributeDataChangeListioner();
    window.addEventListener('DOMCharacterDataModified', DataAttributeDataChangeListioner);
  }
}

module.exports = {
  contentEdit,
  contextEditorPatchApi,
  DataAttributeDataChangeListioner,
  RemoveDataAttributeDataChangeListioner,
  AddDataAttributeDataChangeListioner
};

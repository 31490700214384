const global_variables = require('../../config');
const { contextEditorPatchApi } = require('../services/incontext-editor-service');
const { isTextTranslatedText } = require('./app-helpers');
const { checkIfStringIsLocal } = require('./language-helpers');
const { isLiveJs } = require('./mode');
const { cleanString, sourceLanguageCleanString } = require('./utils');

exports.nodeHelper = (node) => {
  return {
    isA(name) {
      return node && node.nodeName && node.nodeName.toLowerCase() === name;
    },

    isAnImage() {
      return this.isA('img');
    }
  };
};

exports.isNodeASentence = (node) => {
  if (!global_variables.enable_sentence_merging) {
    return false;
  }
  let result = true;
  const parentName = node.nodeName.toUpperCase();
  if (global_variables.sentence_parents.includes(parentName)) {
    if (node.childNodes.length > 1) {
      for (const innerNode of node.childNodes) {
        const childName = innerNode.nodeName.toUpperCase();
        if (!global_variables.sentence_children.includes(childName)) {
          result = false;
        } else if (innerNode.childNodes.length > 1) {
          result = false;
        }
      }
    } else {
      result = false;
    }
  } else {
    result = false;
  }
  return result;
};

// this function is used for setting hindi text to html dom element
exports.setTranslatedTextToElement = (
  element,
  translatedText,
  originalText,
  disableOrigTextCheck = false,
  attributeKeyName = ''
) => {
  if (global_variables.selected_lang_code !== global_variables.source_lang_code) {
    if (
      isTextTranslatedText(sourceLanguageCleanString(originalText)) ||
      (isTextTranslatedText(element.textContent) &&
        element.nodeName &&
        !['META', 'INPUT', 'TEXTAREA', 'IMG'].includes(element.nodeName))
    ) {
      return;
    }
  }

  if (attributeKeyName === 'title') {
    if (originalText && element && element.title) {
      if (
        sourceLanguageCleanString(element.title.trim().toLowerCase()) ===
        (sourceLanguageCleanString(originalText.trim().toLowerCase()) || disableOrigTextCheck)
      ) {
        element.title = translatedText;
      }
    }
    return;
  }

  if (element.nodeName == 'INPUT') {
    if (element.type === 'button') {
      if (element.value === originalText) {
        element.value = translatedText;
      }
    } else {
      if (element.type === 'submit') {
        if (element.value === originalText) {
          element.value = translatedText;
        }
      }

      if (element.placeholder === originalText) {
        element.placeholder = translatedText;
      }

      if (element.type != 'hidden') {
        if (element.title && element.title === originalText) {
          element.title = translatedText;
        }
      }
    }
  } else if (element.nodeName == 'META') {
    if (
      (element.name &&
        ['DESCRIPTION', 'TITLE', 'KEYWORDS', 'TWITTER:TITLE', 'TWITTER:DESCRIPTION'].includes(
          element.name.toUpperCase()
        )) ||
      (element.getAttribute('property') &&
        ['OG:TITLE', 'OG:DESCRIPTION'].includes(element.getAttribute('property').toUpperCase()))
    ) {
      if (translatedText.startsWith(global_variables.meta_prefix)) {
        translatedText = translatedText.replace(global_variables.meta_prefix, '');
      }
      element.content = translatedText;
      // meta editor
      if (element.name) {
        global_variables.metatag_key = `${element.nodeName}-${element.name}`;
        global_variables.metatag_arr[`${global_variables.metatag_key}`] = {
          ...global_variables.metatag_arr[`${global_variables.metatag_key}`],
          content: element.content,
          translatedText: element.content
        };
      } else if (element.getAttribute('property')) {
        global_variables.metatag_key = `${element.nodeName}-${element.getAttribute('property')}`;
        global_variables.metatag_arr[`${global_variables.metatag_key}`] = {
          ...global_variables.metatag_arr[`${global_variables.metatag_key}`],

          content: element.content,
          translatedText: element.content
        };
      }
    }
  } else if (element.nodeName == 'TEXTAREA') {
    if (
      element.placeholder &&
      // eslint-disable-next-line prettier/prettier
      element.placeholder.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase()
    ) {
      element.placeholder = translatedText;
    }
  } else if (element.nodeName == 'IMG') {
    if (translatedText.startsWith(global_variables.meta_prefix)) {
      translatedText = translatedText.replace(global_variables.meta_prefix, '');
    }
    if (originalText.startsWith(global_variables.meta_prefix)) {
      originalText = originalText.replace(global_variables.meta_prefix, '');
    }
    if (
      element.alt &&
      // eslint-disable-next-line prettier/prettier
      element.alt.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase()
    ) {
      element.alt = translatedText;
    }

    if (
      element.title &&
      element.title.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase()
    ) {
      element.title = translatedText;
    }
  } else if (element.nodeName == 'SELECT') {
    if (
      element.title &&
      element.title.replaceAll('\n', '').trim().toLowerCase() ===
        originalText.replaceAll('\n', '').trim().toLowerCase()
    ) {
      element.title = translatedText;
    }
  } else if (element.nodeName == 'A') {
    //if (element.title && element.title.replaceAll("\n", "").trim().toLowerCase() === originalText.replaceAll("\n", "").trim().toLowerCase())
    {
      element.title = translatedText;
    }
  } else {
    if (translatedText.startsWith(global_variables.meta_prefix)) {
      translatedText = translatedText.replace(global_variables.meta_prefix, '');
    }
    if (originalText.startsWith(global_variables.meta_prefix)) {
      originalText = originalText.replace(global_variables.meta_prefix, '');
    }

    if (
      sourceLanguageCleanString(element.textContent.toLowerCase()) ===
        sourceLanguageCleanString(originalText.toLowerCase()) ||
      disableOrigTextCheck ||
      isLiveJs()
    ) {
      // to prevent triggering of DOMCharacterDataModified event even if we have english text.
      if (
        (sourceLanguageCleanString(originalText) != sourceLanguageCleanString(translatedText) &&
          checkIfStringIsLocal(translatedText, true)) ||
        disableOrigTextCheck
      ) {
        element.textContent = translatedText;
      }

      // element.textContent = translatedText;
    }
  }
};

exports.contentEditt = (node) => {
  global_variables.edit_mode_enabled = true;
  // handling the script tag and other unnecesaary text
  const blacklistedTags = ['IMG', 'VIDEO', 'IFRAME', 'NOSCRIPT', 'STYLE', 'SCRIPT'];
  if (node.childNodes && node.childNodes.length > 0) {
    if (!blacklistedTags.includes(node.nodeName.toUpperCase())) {
      for (const innerNode of node.childNodes) {
        contentEditt(innerNode);
      }
    }
  } else {
    if (
      node.parentElement.getAttribute('id') === 'devnagri_optionElementt' ||
      node.parentElement.getAttribute('class') === 'devnagri_optionElement' ||
      node.parentElement.getAttribute('devnagri_option') === 'devnagri_optionElement' ||
      node.parentElement.getAttribute('id') === 'devnagri_seo_button_id'
    ) {
      return true;
    }
    switch (node.nodeName) {
      case '#text': {
        if (node.textContent.trim().length != 0) {
          if (node.parentElement.nodeName !== 'BODY') {
            // english_attr atrribute will contains english text for it's corresponding translated text
            node.parentElement.setAttribute('contentEditable', 'true');
          }
        }
        break;
      }
    }
  }
};

// this function willl call patch api for sending edited meta tag
exports.seoEditedText = () => {
  for (const key of Object.keys(global_variables.metatag_arr)) {
    global_variables.content_editable.push({
      sentence: global_variables.metatag_arr[`${key}`].engText,
      translation: global_variables.metatag_arr[`${key}`].translatedText
    });
  }
  contextEditorPatchApi();
  global_variables.content_editable.length = 0;
};

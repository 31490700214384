const { getClientConfig } = require('../helpers/utils');
const { isProxy } = require('../helpers/mode');

const globalConfig = require('../../config');
const clientConfig = getClientConfig('mygov');

/**
 * Perform initialization tasks for this feature
 *
 * @param {object} config Configuration passed by initialization code
 * @param {object} urlObj Document URL object
 */
exports.initialize = async (config, urlObj) => {
  console.log('client outer');
  if (
    urlObj.hostname.includes(clientConfig.hostname[0]) ||
    urlObj.hostname.includes(clientConfig.hostname[1]) ||
    urlObj.hostname.includes(clientConfig.hostname[2])
  ) {
    console.log('client inner mygov');
    clientConfig.enabled = true;

    addEventListener('DOMContentLoaded', (event) => {
      let favIcons = document.getElementsByTagName('link');
      for (let favIcon of favIcons) {
        //console.log(favIcon.getAttribute("rel"))
        if (favIcon.getAttribute('rel') == 'shortcut icon') {
          //console.log(favIcon.getAttribute("rel"))
          let Linkhref = favIcon.getAttribute('href');
          favIcon.setAttribute('href', Linkhref.replace(`${document.documentElement.lang}/`, ''));
        }
      }

      let LakhArray = {
        hi: 'लाख',
        pa: 'ਲੱਖ',
        ta: 'லட்சம்',
        gu: 'લાખ',
        bn: 'লক্ষ',
        kn: 'ಲಕ್ಷ',
        mr: 'लाख',
        te: 'లక్షలు',
        ml: 'ലക്ഷം',
        as: 'লাখ',
        or: 'ଲକ୍ଷ'
      };
      let LakhContent = document.getElementsByClassName('postfix_name');
      let languageCode = document.querySelector('html').getAttribute('lang');
      for (let l of LakhContent) {
        l.innerHTML = ` + ${LakhArray[languageCode]}`;
      }
    });

    globalConfig.missingTranslationPercentageOffsetForRetry = 100;
    globalConfig.enable_index_db = false;
  }
};

/**
 * Is used to check if the client code is enabled or not.
 *
 * @returns boolean
 */
exports.isEnabled = () => {
  return !!clientConfig.enabled;
};

/**
 * Perform initialization tasks for this feature on server side
 *
 * @param {object} config Configuration passed by initialization code
 * @param {object} urlObj Document URL object
 */
exports.initializeServer = async (config, urlObj) => {
  console.log('client outer server');
  if (
    urlObj.hostname.includes(clientConfig.hostname[0]) ||
    urlObj.hostname.includes(clientConfig.hostname[1])
  ) {
    console.log('client inner server mygov');
    clientConfig.enabled = true;

    globalConfig.missingTranslationPercentageOffsetForRetry = 100;
  }
};

// TODO: move to a standardised fix
// For load more content urls issue
exports.fixForLinksMissMatchWithDynamicContent = async () => {
  // fix for a tag
  if (isProxy()) {
    const aTags = document.getElementsByTagName('a');
    //Get Language DropDown A Tags
    const aTagsLanguageSwitcher = document.getElementsByClassName('language-link ');
    let aTagsLanguageSwitcherArray = [];
    for (let aTagLS of aTagsLanguageSwitcher) {
      aTagsLanguageSwitcherArray.push(aTagLS.getAttribute('href'));
    }
    for (let aTag of aTags) {
      let href = aTag.getAttribute('href');

      //Prevent for Language Dropdown
      if (aTagsLanguageSwitcherArray.includes(href)) {
        continue;
      }
      if (
        href &&
        !href.includes(`https://${window.location.hostname}/${document.documentElement.lang}`) &&
        href.includes(`https://${window.location.hostname}`)
      ) {
        aTag.setAttribute(
          'href',
          href.replace(
            `https://${window.location.hostname}`,
            `https://${window.location.hostname}/${document.documentElement.lang}`
          )
        );
      }
      if (href && href[0] == '/') {
        aTag.setAttribute(
          'href',
          href.replace(`/`, `https://${window.location.hostname}/${document.documentElement.lang}/`)
        );
      }
    }
  }
};

const { getFeatureConfig } = require('../helpers/utils');
const { nodeHelper } = require('../helpers/node-helpers');
const { formatUrl, getLocalObjectUrl, getLocalObjectAlt } = require('../helpers/app-helpers.js');

const featureConfig = getFeatureConfig('objectMapping');

// For backward compatibility, this should be removed after code refactoring.
const globalConfig = require('../../config');

/**
 * Perform initialization tasks for this feature
 *
 * @param {object} config Configuration passed by initialization code
 */
exports.initialize = async (config) => {
  featureConfig.enabled = config.enable_object_mapping;
};

/**
 * Is used to check if the feature is enabled or not.
 *
 * @returns boolean
 */
exports.isEnabled = () => {
  return !!featureConfig.enabled;
};

/**
 * Traverse over nodes to localized media/asset urls.
 *
 * @param {object} node Single dom node
 * @param {function|undefined} callback Callback to handle post tracversing action
 * @returns object
 */
exports.traverseNodes = async (node, callback) => {
  if (nodeHelper(node).isAnImage()) {
    for await (const handler of handlers) {
      node = await handler(node);
    }
  }

  return node;
};

/**
 * Various handlers to perform different actions on given node.
 */
const handlers = [
  async (node) => {
    if (node.src) {
      let localUrl = '';
      let objectEnglishUrl = '';

      // If the url is absolute
      if (node.src.includes('http')) {
        globalConfig.requested_domain = !globalConfig.requested_domain.endsWith('/')
          ? globalConfig.requested_domain + '/'
          : globalConfig.requested_domain;
        objectEnglishUrl = node.src.replaceAll(globalConfig.requested_domain, globalConfig.domain);
      } else {
        objectEnglishUrl = `${globalConfig.domain}/${node.src}`;
      }
      localUrl = getLocalObjectUrl(objectEnglishUrl);

      const localAlt = getLocalObjectAlt(objectEnglishUrl);

      if (localUrl) {
        node.src = localUrl;
      }
      if (localAlt) {
        node.alt = localAlt;
      }
    }
    return node;
  },

  async (node) => {
    if (node.getAttribute('srcset')) {
      const value = node.getAttribute('srcset');
      let valueWithLocalLinks = value;
      Object.values(globalConfig.subdomain_object_urls_hash_map).forEach((object) => {
        valueWithLocalLinks = valueWithLocalLinks.replaceAll(
          formatUrl(object.english_url),
          formatUrl(object.local_url)
        );
      });
      node.setAttribute('srcset', valueWithLocalLinks);
    }
    return node;
  },

  async (node) => {
    if (node.getAttribute('data-lazy-src')) {
      const value = node.getAttribute('data-lazy-src');
      let valueWithLocalLinks = value;

      Object.values(globalConfig.subdomain_object_urls_hash_map).forEach((object) => {
        valueWithLocalLinks = valueWithLocalLinks.replaceAll(
          formatUrl(object.english_url),
          formatUrl(object.local_url)
        );
      });
      node.setAttribute('data-lazy-src', valueWithLocalLinks);
    }
    return node;
  },

  async (node) => {
    if (node.getAttribute('data-lazy-srcset')) {
      const value = node.getAttribute('data-lazy-srcset');
      let valueWithLocalLinks = value;
      Object.values(globalConfig.subdomain_object_urls_hash_map).forEach((object) => {
        valueWithLocalLinks = valueWithLocalLinks.replaceAll(
          formatUrl(object.english_url),
          formatUrl(object.local_url)
        );
      });
      node.setAttribute('data-lazy-srcset', valueWithLocalLinks);
    }
    return node;
  }
];
